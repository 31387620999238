import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import { GoogleApiWrapper, Marker } from 'google-maps-react';

import StaticMap from 'components/maps/MultiMarkerMap/StaticMap';

import { StyledMapContainer, StyledGoogleMapsButton } from 'components/maps/styled';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const SingleMarkerMap = ({ destinationLocationData, google, zoom, slice, language }) => {

  const [staticmap, setStaticMap] = useState(true);

  const showDynamicMap = ()=> {
    setStaticMap(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", showDynamicMap);
    return () => window.removeEventListener("scroll", showDynamicMap);
}, []);

  const data = useStaticQuery(graphql`
    query mapPropertiesQuery {
      allPrismicUniversalProperties {
        edges {
          node {
            lang
            data {
              google_maps_link_button_text
              map_icon {
                url
              }
            }
          }
        }
      }
    }
  `)

  const mapProperties = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
  
  const coords = {
    lat: destinationLocationData && destinationLocationData.latitude,
    lng: destinationLocationData && destinationLocationData.longitude
  };
  
  const googleMapsURL = `http://maps.google.com/maps?q=${coords.lat},${coords.lng}`;

  return (
    <React.Fragment>
      { staticmap === true ? 
        (
          <StaticMap 
            lat={coords.lat} 
            long={coords.lng} 
            zoom={9}
          />
        ) : 
        (
          <div style={{textAlign: 'center', position: 'relative'}}>
            <StyledMapContainer
              google={google}
              zoom={zoom ? zoom : 9}
              initialCenter={coords}
            >
              <Marker icon={mapProperties.node.data.map_icon.url} />
            </StyledMapContainer>

            <StyledGoogleMapsButton href={googleMapsURL} target="blank">
              {mapProperties.node.data.google_maps_link_button_text}
            </StyledGoogleMapsButton>
          </div>
        )
      }
    </React.Fragment>
  );
};

SingleMarkerMap.propTypes = {
  destinationLocationData: PropTypes.object,
  google: PropTypes.object,
  language: PropTypes.string,
  slice: PropTypes.object,
  zoom: PropTypes.number
};

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY,
  libraries: ['places', 'geometry'],
})(SingleMarkerMap);